import { parse, differenceInSeconds, isDate } from 'date-fns';
export function calcularDiferencaEmSegundos(inicio: Date | string, fim: Date | string): number {
  // Converter as strings para objetos Date, se necessário
  const inicioDate = isDate(inicio)
    ? inicio
    : parse(inicio as string, 'yyyy-MM-dd HH:mm:ss', new Date());
  const fimDate = isDate(fim) ? fim : parse(fim as string, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Calcular a diferença em segundos
  const diferencaEmSegundos = differenceInSeconds(inicioDate as Date, fimDate as Date);

  return diferencaEmSegundos;
}
