import React from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { IFormActivityCopy } from '../../@Types/IFormActivityCopy';
import SelectWorkflow from 'app/components/Inputs/Selects/SelectWorkflow/SelectWorkflow';
import SelectWorkflowPhases from 'app/components/Inputs/Selects/SelectPhase/SelectPhase';
import {
  IDataActivityCopyErrors,
  IDataCopyActivity,
  initialCopyActivity,
} from 'app/types/data/IDataActivity';
import { IDataWorkflow } from 'app/types/data/IDataWorkflow';
import { LoadingButton } from '@mui/lab';
import { IDataWorkflowPhases } from 'app/types/data/IDataWorkflowPhases';
import { ActivityCopySchema } from '../../@Schemas/ActivityCopySchema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IToast } from 'app/types/IToast';
import { useToast } from 'app/hooks/useToast';
import Activity_Api from 'app/api/Activity_Api';
import { setDataValue } from 'app/utils/utils';
import AlertMessage from 'app/components/Alert';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const to_keep_options = [
  { name: 'Predecessor', value: 'predecessor' },
  { name: 'Sub-Atividades', value: 'children' },
  { name: 'Motivos', value: 'motivos' },
  { name: 'Notificações', value: 'notificacoes' },
  { name: 'Anexos', value: 'media' },
];

const CREATE_MSG = 'Atividades copiadas com sucesso.';

const initialToast: IToast = {
  open: false,
  message: CREATE_MSG,
  severity: 'success',
};
const ActivityCopyForm = (props: IFormActivityCopy) => {
  const [formData, setFormData] = React.useState<IDataCopyActivity>(initialCopyActivity);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { showToast } = useToast();

  React.useEffect(() => {}, [formData]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataCopyActivity>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(ActivityCopySchema),
  });

  function setWorkflow(workflow?: IDataWorkflow) {
    if (workflow) {
      const newData = { ...formData, workflow_id: workflow.id as number, workflow };
      setFormData(newData);
      setDataValue(newData, setValue);
    } else {
      const newData = {
        ...formData,
        workflow_id: null,
        workflow: null,
        stage_id: null,
        stage: null,
      };
      setFormData(newData);
      setDataValue(newData, setValue);
    }
  }

  function setStage(stage?: IDataWorkflowPhases) {
    if (stage) {
      const newData = { ...formData, stage_id: stage.id as number, stage };
      setFormData(newData);
      setDataValue(newData, setValue);
    } else {
      const newData = { ...formData, stage_id: null, stage: null };
      setFormData({ ...formData, stage_id: null, stage: null });
      setDataValue(newData, setValue);
    }
  }

  async function onSubmit(dataForm: IDataCopyActivity) {
    const { workflow_id, stage_id } = dataForm;
    if (!workflow_id || !stage_id) {
      return;
    }
    setLoading(true);
    try {
      await Activity_Api.clone({
        workflow_id,
        stage_id,
        to_keep: formData.to_keep,
        id: props.activities,
      });

      showToast({
        open: true,
        message: CREATE_MSG,
        severity: initialToast.severity,
      });
      clearErrors();
      reset();
      if (props.onSubmit) props.onSubmit();
    } catch (error: IDataActivityCopyErrors | unknown) {
      if (error) {
        const err = error as IDataActivityCopyErrors;
        console.log(err);
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));

        if (err.errors?.message) {
          showToast({
            open: true,
            message: err.errors.message,
            severity: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormBox id="formik-Activity" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="workflow_id"
            control={control}
            defaultValue={formData.workflow_id}
            render={({ field: { onChange } }) => (
              <SelectWorkflow
                id="workflow_id_to_copy"
                label="Workflow"
                placeholder={'Selecione o Workflow de destino'}
                allWorkflows
                showSecondaryOption
                value={formData.workflow || undefined}
                error={errors.workflow_id?.message}
                onChange={(workflow: IDataWorkflow | undefined) => {
                  setWorkflow(workflow);
                  onChange(workflow?.id);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="stage_id"
            control={control}
            defaultValue={formData.stage_id}
            render={({ field: { onChange } }) => (
              <SelectWorkflowPhases
                id="stage_id_to_copy"
                value={formData.stage}
                label="Etapa do Workflow"
                placeholder="Selecione para qual Etapa do Workflow será a cópia"
                disabled={!Boolean(formData.workflow_id)}
                error={errors.stage_id?.message}
                relation={
                  formData.workflow_id
                    ? { id: formData.workflow_id as number, relation: 'Workflow' }
                    : undefined
                }
                onChange={(stage: IDataWorkflowPhases | undefined) => {
                  setStage(stage);
                  onChange(stage?.id);
                }}
                getCurrentSelected={setStage}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', marginBottom: -10, marginTop: 1 }}>
          <Typography variant="body1" fontWeight={500}>
            Manter
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={formData.to_keep?.includes(option.value)}
                      onChange={() => {
                        if (formData.to_keep?.includes(option.value)) {
                          const filteredOptions = formData.to_keep.filter(
                            (opt) => opt !== option.value
                          );
                          setFormData({ ...formData, to_keep: filteredOptions });
                        } else {
                          setFormData({
                            ...formData,
                            to_keep: [...(formData.to_keep as string[]), option.value],
                          });
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <AlertMessage
            title="Atenção"
            severity="info"
            variant="standard"
            message="Caso as atividades fiquem fora dos limites da Etapa do Workflow de destino, 
                     estas receberão as datas da própria etapa, de acordo com a data da atividade (início ou fim) que passar dos limites desta."
          />
        </Grid>
      </Grid>
      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={5}>
        <Button onClick={props.onCancel} color="primary">
          Cancelar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Copiar
        </LoadingButton>
      </Grid>
    </FormBox>
  );
};

export default ActivityCopyForm;
