import { Box, Button } from '@mui/material';
import QRCode from 'qrcode.react';

interface QRCodeImageProps {
  downloadFileName: string;
  qrCodeLink: string;
  size?: number;
  downloadImageSize?: number;
}

const QRCodeImage = ({
  downloadFileName = 'qr-code',
  qrCodeLink,
  size = 80,
  downloadImageSize = 400,
  ...props
}: QRCodeImageProps) => {
  const downloadQRCode = () => {
    const svgElement = document.getElementById(
      'qrcode-vitrine-virtual-produto-enlarged'
    ) as HTMLCanvasElement;

    // Convert the canvas to a data URL
    const image = svgElement.toDataURL('image/png');

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');

    // Set the href attribute to the data URL
    downloadLink.href = image;

    // Set the download attribute to specify the filename
    downloadLink.download = downloadFileName + '.png';

    // Append the anchor element to the document body
    document.body.appendChild(downloadLink);

    // Programmatically trigger a click event on the anchor element
    downloadLink.click();

    // Remove the anchor element from the document body
    document.body.removeChild(downloadLink);
  };

  return (
    <Box width="max-content" display="flex" flexDirection="column">
      <QRCode id="qrcode-vitrine-virtual-produto" value={qrCodeLink} size={size} {...props} />
      <QRCode
        id="qrcode-vitrine-virtual-produto-enlarged"
        value={qrCodeLink}
        size={downloadImageSize}
        {...props}
        style={{ display: 'none' }}
      />
      <Box width="100%">
        <Button fullWidth variant="text" onClick={downloadQRCode}>
          Baixar
        </Button>
      </Box>
    </Box>
  );
};

export default QRCodeImage;
