import { IDataErrors } from 'app/types/data/IData';

export function convertErroToString(errorObj: IDataErrors) {
  let resultado = '';

  for (const chave in errorObj) {
    if (errorObj.hasOwnProperty(chave)) {
      const mensagens = errorObj[chave];
      if (!Array.isArray(mensagens)) {
        return mensagens;
      }

      // eslint-disable-next-line no-loop-func
      mensagens.forEach((mensagem: string) => {
        resultado += `${mensagem}\n`;
      });
    }
  }

  return resultado.trim();
}
