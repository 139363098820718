import React from 'react';
import { IDataWorkflow } from 'app/types/data/IDataWorkflow';
import Modal from 'app/components/Modal';
import { IDataErrors } from 'app/types/data/IData';
import AlertMessage from 'app/components/Alert';
import Toast from 'app/components/Toast';
import { getMessage } from 'app/utils/messages';
import ActivityCopyForm from 'app/components/Forms/Activity/CopyActivity';

export declare interface FormModalType {
  activities: string[] | number[];
  title: string;
  message: string | React.ReactNode;
  open: boolean;
  workflow?: IDataWorkflow;
  errors?: IDataErrors;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
}

const CREATE_MSG = getMessage('Copiar atividades', 'create');

const CloneActivities = (props: FormModalType) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const handleClose = () => setOpenToast(false);

  return (
    <>
      <Modal open={props.open} maxWidth="sm" onClose={props.onClose} title="Copiar Atividades">
        {props.errors?.message && (
          <AlertMessage title={props.errors.status || '500'} message={props.errors.message} />
        )}
        <ActivityCopyForm
          activities={props.activities}
          onCancel={props.onClose}
          onSubmit={props.onConfirm}
        />
      </Modal>
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default CloneActivities;
