import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const convertToSeconds = (input: string): number => {
  if (!input) return 0;

  if (input.includes(':')) {
    const [hours, minutes] = input.split(':').map(Number);
    return hours * 3600 + minutes * 60;
  } else {
    return parseFloat(input.replace(',', '.')) * 3600;
  }
};

const convertToDisplay = (time: number | string): string => {
  if (time === '') {
    time = '0';
  }
  const seconds = typeof time === 'string' ? parseFloat(time.replace(',', '.')) * 3600 : time;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

interface IDurationInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: number | string;
  placeholder?: string;
  onChange: (newValue: number) => void;
}

const DurationInput: React.FC<IDurationInputProps> = ({
  value,
  placeholder,
  onChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState<string>(convertToDisplay(value));

  useEffect(() => {
    setInputValue(convertToDisplay(value));
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regex = /^[\d:,.]*$/;
    if (regex.test(value)) {
      setInputValue(value);
    }
  }, []);

  const handleBlur = () => {
    onChange(convertToSeconds(inputValue));
    setInputValue(convertToDisplay(value));
  };

  return (
    <TextField
      label="Duração"
      value={inputValue}
      placeholder="00:00 ou 2,5"
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  );
};

export default DurationInput;
