import Endpoints from 'app/config/Endpoints';
import {
  FuncionalidadeFields,
  IDataFuncionalidade,
  IDataFuncionalidadeErrors,
  IHookFormFuncionalidade,
} from 'app/types/data/IDataFuncionalidade';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';

export default class Funcionalidade_Api extends Model_Api {
  static self = new Funcionalidade_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataFuncionalidadeErrors {
    const resp: IDataFuncionalidadeErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormFuncionalidade[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as FuncionalidadeFields;
      const item: IHookFormFuncionalidade = {
        type: 'manual',
        nome: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async listCombo(): Promise<IDataFuncionalidade[]> {
    try {
      return (
        await (await Api.get(`${Endpoints.Functionalities.main}/combo`)).data as IDataFuncionalidade[]
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }


  static async listComboAll(): Promise<IDataFuncionalidade[]> {
    try {
      return (
        await (await Api.get(`${Endpoints.Functionalities.main}/combo-all`)).data as IDataFuncionalidade[]
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }


  static async get(id: number): Promise<IDataFuncionalidade> {
    try {
      return (
        await (await Api.get(`${Endpoints.Functionalities.main}/get/${id}`)).data as IDataFuncionalidade
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  static async listPai(id: number): Promise<IDataFuncionalidade[]> {
    try {
      return (
        await (await Api.get(`${Endpoints.Functionalities.main}/pai/${id}`)).data as IDataFuncionalidade[]
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  // static async create(
  //   data: IDataFuncionalidade,
  // ): Promise<IDataFuncionalidade> {
  //   try {
  //     const resp = (await Api.post(Endpoints.Menu.main, data)).data as IDataFuncionalidade;

  //     return resp;
  //   } catch (error) {
  //     const handleErros = this.self.handleErrors(error);
  //     console.log('error: Create Menu', handleErros);
  //     throw handleErros;
  //   }
  // }

  // static async update(
  //   data: IDataFuncionalidade,
  // ): Promise<IDataFuncionalidade> {
  //   try {
  //     const resp = (await Api.put(`${Endpoints.Menu.main}/${data.id}`, data)).data;

  //     return resp;
  //   } catch (error) {
  //     const handleErros = this.self.handleErrors(error);
  //     console.log('error: Update Menu', handleErros);
  //     throw handleErros;
  //   }
  // }

  // static async delete(
  //   data: IDataFuncionalidade,
  // ): Promise<IDataFuncionalidade> {
  //   try {
  //     var resp: any;
  //     resp = (await Api.delete(`${Endpoints.Menu.main}/${data.id}`)).data;

  //     return resp;
  //   } catch (error) {
  //     const handleErros = this.self.handleErrors(error);
  //     console.log('error: Delete Menu', handleErros);
  //     throw handleErros;
  //   }
  // }
}
