import { GridSortModel } from '@mui/x-data-grid';
import { IParamsQs } from 'app/types/IParams';

type SetParams<T> = React.Dispatch<React.SetStateAction<IParamsQs<T> | undefined>>;
type SetRowToSelect = React.Dispatch<React.SetStateAction<string | null>>;

const handleOnSortModelChange = <T extends string>(
  model: GridSortModel,
  setterFunc: SetParams<T>,
  setRowToSelect?: SetRowToSelect
) => {
  if (!!model?.length) {
    const sort = model[0].field;
    const order = model[0].sort;
    setterFunc((prevParams) => ({ ...prevParams, sort, order }));
    setRowToSelect && setRowToSelect(null);
  }
};

export default handleOnSortModelChange;
