import { FactCheck } from '@mui/icons-material';
import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import Attributes_Api from 'app/api/Attributes_Api';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import CategorieContext from 'app/contexts/CategorieContext';
import ProjectContext from 'app/contexts/ProjectContext';
import SkuContext from 'app/contexts/SkuContext';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import {
  AttributeRelations,
  IAttributePagination,
  IDataAttribute,
  IDataAttributeErrors,
  IDataFilterAttribute,
} from 'app/types/data/IDataAttribute';
import React, { useContext } from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import OptionAttributes from '../Option/OptionAttributes';

export interface ISelectCategories {
  id?: string;
  value?: IDataAttribute;
  disabled?: boolean;
  error?: string;
  onChange?: (attribute?: IDataAttribute) => void;
  //onChangeExtraOptions?: (options: AttributeExtraOptions[]) => void;
  label?: string;
}

const SelectAttributes = (props: ISelectCategories) => {
  const projectCtx = useContext(ProjectContext);
  const productCtx = useContext(CatalogProductContext);
  const skuCtxt = useContext(SkuContext);
  const categorieCtxt = React.useContext(CategorieContext);

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataAttribute>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataAttribute> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataAttribute> | undefined = props.value
      ? {
          label: props.value?.nm_atributo,
          value: props.value?.id as number,
          nivel: props.value.nivel,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      load();
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function load() {
    setLoading(true);
    try {
      var relation: IDataRelation<AttributeRelations> | undefined = undefined;
      //Select dentro de uma loja dentro de um projeto retorna somente os Categoriess cadastrados no projeto
      if (projectCtx && (productCtx || skuCtxt || categorieCtxt)) {
        relation = { id: projectCtx.id as number, relation: 'Project' };
      }

      const params: IDataFilterAttribute = {
        q: search,
        page: 1,
        per_page: 24,
        order: 'asc',
        sort: 'id',
        produto_id:
          (skuCtxt || productCtx) && !categorieCtxt
            ? (productCtx?.id as number) || (skuCtxt?.produto_id as number)
            : undefined,
        is_null: categorieCtxt ? 'parent_id' : undefined,
      };

      var items: IAttributePagination | undefined = undefined;

      if (!projectCtx) items = await Attributes_Api.list(params);
      else if (categorieCtxt) items = await Attributes_Api.list(params, relation);
      else if (skuCtxt) items = await Attributes_Api.listSku(params, relation);
      else if (productCtx) items = await Attributes_Api.listProduto(params, relation);
      else items = await Attributes_Api.list(params, relation);

      const combo: ISelectOption<IDataAttribute>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.nm_atributo,
        nivel: item.nivel,
        data: item,
      }));
      setComboData(combo);
    } catch (error: IDataAttributeErrors | unknown) {
      if (error) {
        const err = error as IDataAttributeErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != 403) setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Autocomplete
        id={props.id}
        noOptionsText="Nenhuma categoria encontrada"
        options={comboData}
        defaultValue={comboOption}
        value={comboOption || null}
        loading={loading}
        loadingText="Buscando..."
        onClose={() => setSearch('')}
        disabled={props.disabled}
        getOptionDisabled={(option) =>
          (productCtx || skuCtxt) && !option.data?.parent_id && !categorieCtxt ? true : false
        }
        //disableCloseOnSelect
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option && option.data) {
            setComboOption(option);
            if (props.onChange) props.onChange(option.data);
          } else {
            setComboOption(undefined);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(props, option) => (
          <OptionAttributes
            key={option.value}
            props={props}
            option={option}
            secondary={`Alias: ${option.data?.alias} ${
              ['produto', 'sku'].includes(option.data?.relative_to?.value as string)
                ? `/ Class.: ${option.data?.relative_to?.value}`
                : ''
            } ${option.data?.parent ? `/ Atributo: ${option.data.parent.nm_atributo}` : ''}
            `}
          />
        )}
        renderInput={(params) => (
          <TimedTextField
            {...params}
            label={
              props.label ||
              `Atributos ${(productCtx || skuCtxt) && projectCtx ? 'do projeto' : 'Agnósticas'}`
            }
            placeholder={`Pesquisar um atributo ${
              projectCtx && (productCtx || skuCtxt || categorieCtxt)
                ? 'do projeto'
                : 'da base agnóstica'
            }`}
            type="text"
            size="small"
            timer={300}
            onChange={(e) => {
              setSearch(e.target.value);
              if (props.onChange) props.onChange(undefined);
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <FactCheck />
                </InputAdornment>
              ),
            }}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors}
              </Typography>
            }
          />
        )}
      />
    </>
  );
};

export default SelectAttributes;
