import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Sku_Api from 'app/api/Sku_Api';
import AlertMessage from 'app/components/Alert';
import EanInput from 'app/components/Inputs/EanInput/EanInput';
import MoneyInput from 'app/components/Inputs/MoneyInput/MoneyInput';
import SelectCurator from 'app/components/Inputs/Selects/SelectCurator/SelectCurator';
import SelectProduct from 'app/components/Inputs/Selects/SelectProduct/SelectProduct';
import SelectSku from 'app/components/Inputs/Selects/SelectSku/SelectSku';
import Toast from 'app/components/Toast';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataOriginSku, IDataSku, IDataSkuErrors, SkuExtraOptions } from 'app/types/data/IDataSku';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SkuSchema } from '../@Schemas/SkuSchema';
import { IFormSku } from '../@Types/IFormSku';
import Swal from 'sweetalert2';
import Parametro_Api from 'app/api/Parametro_Api';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

export const initialValues: IDataSku = {
  id: null,
  sku: '',
  name: '',
  short_name: '',
  smart_description: '',
  ds_produto: '',
  nr_ean: '',
  vl_precode: 0,
  vl_precopor: 0,
  nr_estoque: 0,
  nr_estoque_minimo: 0,
  nr_largura: 0,
  nr_altura: 0,
  nr_profundidade: 0,
  nr_peso: 0,
  nr_largura_logistica: 0,
  nr_altura_logistica: 0,
  nr_profundidade_logistica: 0,
  nr_peso_logistica: 0,
  ficha_tecnica: '',
  medias: [],
  status_umami: null,
  quality_status: null,
};

const UPDATE_MSG = getMessage('Sku', 'update');
const CREATE_MSG = getMessage('Sku', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SkuForm = (props: IFormSku) => {
  const { resetFormList } = useUtilsContext();
  const productContext = React.useContext(CatalogProductContext);

  const [data, setData] = React.useState<IDataSku>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [extraOptions, setExtraOptions] = React.useState<SkuExtraOptions[]>([]);

  React.useEffect(() => {
    setData((props.data as IDataSku) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.productForm, props.data]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataSku>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SkuSchema),
    context: { productContext: Boolean(productContext) },
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataSku) {
    setLoading(true);
    try {
      const param = await Parametro_Api.showByName("Alerta de geração de SKU");
      if (param.valor == '1') {
        if (!data.id && !data.sku) {
          const resp = await Swal.fire({
            title: 'Atenção',
            text: 'Você está salvando um sku sem código. Podemos gerá-lo automaticamente?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          });
          if (!resp.isConfirmed) return;
        }
      }
      const config = {
        params: {
          permite_envio_api: 'Sim',
        }
      };

      var newData: IDataSku;
      if (data.id) {
        newData = await Sku_Api.update(data, props.relation, config);
      } else {
        newData = await Sku_Api.create(data, props.relation, extraOptions, config);
        if (props.onSubmit) props.onSubmit(newData);
      }

      if (props.typeForm && props.typeForm === 'create' && props.sequency && props.onSubmit) {
        props.onSubmit();
      }

      setData(newData);
      setDataValue(newData, setValue);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <FormBox id="formik-Product" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {props.relation && (
          <Box display="none">
            <Grid item xs={12} marginBottom={5}>
              <SelectSku
                value={data.origin as IDataSku}
                onChange={(sku) => {
                  if (sku) {
                    const newData: IDataSku = {
                      ...sku,
                      id: null,
                      origin_id: sku.id as number,
                      origin: sku as IDataOriginSku,
                    };
                    const { produto, produto_id, ...rest } = newData;
                    setDataValue(rest, setValue);
                    setData({
                      ...rest,
                      id: null,
                    });
                  } else {
                    setDataValue(initialValues, setValue);
                    setData(initialValues);
                  }
                }}
                onChangeExtraOptions={(options) => setExtraOptions(options)}
              />
            </Grid>
          </Box>
        )}
        {!productContext && (
          <Grid item xs={12}>
            <Controller
              name="produto_id"
              control={control}
              defaultValue={data.produto_id}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <SelectProduct
                  relation={props.relation}
                  error={errors?.produto_id?.message}
                  id="produto_id"
                  value={data.produto}
                  disabled={!!data?.id}
                  onChange={(product) => {
                    if (product)
                      Parametro_Api.showByName("Copia de Sku do agnóstico").then(async param => {
                        if ((product['skus'] ?? []).length > 0)
                          if ((product['skus'] ?? []).every(f => f.quality_status.label != param.valor)) {

                            const resp = await Swal.fire({
                              title: 'Atenção',
                              text: `Este produto não está com o Status Qualidade: ${param.valor}. Deseja realmente prosseguir?`,
                              showCancelButton: true,
                              showConfirmButton: true,
                              confirmButtonText: 'Sim',
                              cancelButtonText: 'Não'
                            });

                            if (!resp.isConfirmed) return;
                          }

                        setData((prev) => ({
                          ...prev,
                          produto_id: product?.id as number,
                          produto: product,
                        }));
                        setValue('produto_id', product?.id as number);
                        setValue('produto', product);
                        onChange(product?.id as number);
                      });
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name="origin_id"
            control={control}
            defaultValue={data.origin_id}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <SelectSku
                value={data.origin as IDataSku}
                onChange={(sku) => {



                  if (data.id) {
                    const newData = {
                      ...data,
                      origin_id: sku ? (sku.id as number) : null,
                    };
                    setData(newData);
                    setDataValue(newData, setValue);
                  } else if (sku) {
                    const newData: IDataSku = {
                      ...sku,
                      id: null,
                      produto_id: (productContext?.id as number) || data.produto_id,
                      produto: productContext || data.produto,
                      origin_id: sku.id as number,
                      origin: sku as IDataOriginSku,
                    };
                    setData(newData);
                    setDataValue(newData, setValue);
                  } else {
                    setData(initialValues);
                    setDataValue(initialValues, setValue);
                  }


                }}
                onChangeExtraOptions={data.id ? undefined : (options) => setExtraOptions(options)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <Controller
            name="id"
            control={control}
            defaultValue={data.id}
            render={({ field: { onChange } }) => (
              <TextField
                id="id"
                label="ID"
                type="text"
                disabled={true}
                value={data.id}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: Boolean(data?.id) }}
                size="small"
                onChange={(e) => {
                  setData({ ...data, id: parseInt(e.target.value) });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="sku"
            control={control}
            defaultValue={data.sku}
            render={({ field: { onChange } }) => (
              <TextField
                id="sku"
                label="SKU*"
                type="text"
                value={data.sku || ''}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.sku?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, sku: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            name="name"
            control={control}
            defaultValue={data.name}
            render={({ field: { onChange } }) => (
              <TextField
                id="name"
                label="Nome*"
                type="text"
                value={data.name || ''}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.name?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="short_name"
            control={control}
            defaultValue={data.short_name}
            render={({ field: { onChange } }) => (
              <TextField
                id="short_name"
                label="Nome reduzido"
                type="text"
                value={data.short_name || ''}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 32,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.short_name?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, short_name: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="nr_ean"
            control={control}
            defaultValue={data.nr_ean}
            render={({ field: { onChange } }) => (
              <EanInput
                id="nr_ean"
                label="Código de barras"
                type="text"
                value={data.nr_ean || ''}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                InputLabelProps={{ shrink: Boolean(data.nr_ean) }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.nr_ean?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, nr_ean: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="smart_description"
            control={control}
            defaultValue={data.smart_description}
            render={({ field: { onChange } }) => (
              <TextField
                id="smart_description"
                label="Descrição Inteligente"
                type="text"
                value={data.smart_description || ''}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.smart_description?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, smart_description: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="vl_precode"
            control={control}
            defaultValue={data.vl_precode}
            render={({ field: { onChange } }) => (
              <MoneyInput
                id="vl_precode"
                label="Preço de"
                name="vl_precode"
                type="text"
                value={data.vl_precode || ''}
                autoComplete={'off'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.vl_precode?.message}
                  </Typography>
                }
                onBlur={(e) => {
                  if (data.vl_precopor) {

                    if (data.vl_precopor > parseFloat(e.target.value.split('.').join('').replace(",", '.'))) {

                      setToast({
                        open: true,
                        message: "Preço por não pode ser maior que o preço de.",
                        severity: 'warning',
                      });
                      setData({ ...data, vl_precode: 0 });
                      return;
                    }
                  }
                }}
                onChange={(e) => {
                  setData({ ...data, vl_precode: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="vl_precopor"
            control={control}
            defaultValue={data.vl_precopor}
            render={({ field: { onChange } }) => (
              <MoneyInput
                id="vl_precopor"
                name="vl_precopor"
                label="Preço por"
                type="text"
                value={data.vl_precopor}
                autoComplete={'off'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.vl_precopor?.message}
                  </Typography>
                }
                onBlur={(e) => {
                  if (data.vl_precode) {
                    if (parseFloat(e.target.value.split('.').join('').replace(",", '.')) > data.vl_precode) {

                      setToast({
                        open: true,
                        message: "Preço por não pode ser maior que o preço de.",
                        severity: 'warning',
                      });
                      setData({ ...data, vl_precopor: 0 });
                      return;
                    }
                  }

                }}
                onChange={(e) => {
                  setData({ ...data, vl_precopor: parseFloat(e.target.value) });
                  onChange(e);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="curador_id"
            control={control}
            defaultValue={data?.curador_id}
            render={({ field: { onChange } }) => (
              <SelectCurator
                id="curador_id"
                value={data?.curador}
                onChange={(curador) => {
                  setValue('curador_id', curador ? curador.id : '');
                  setValue('curador', curador);
                  setData({
                    ...data,
                    curador_id: curador ? curador.id : '',
                    curador: curador,
                  });
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="status_umami"
            control={control}
            defaultValue={data.status_umami}
            render={({ field: { onChange } }) => (
              <Autocomplete
                options={[
                  { value: 1, label: 'Ativo' },
                  { value: 2, label: 'Inativo' },
                  { value: 3, label: 'Pausado' },
                  { value: 4, label: 'Entregue' },
                  { value: 5, label: 'Ativo com prioridade' },
                ]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                defaultValue={data.status_umami}
                value={data.status_umami}
                onChange={(event, option: any) => {
                  if (option) {
                    setData({ ...data, status_umami: option });
                  }
                  onChange(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="status_umami"
                    label="Status Umami"
                    type="text"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.status_umami?.message}
                      </Typography>
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="quality_status"
            control={control}
            defaultValue={data.quality_status}
            render={({ field: { onChange } }) => (
              <Autocomplete
                options={[
                  { value: 1, label: 'Pré-cadastro iniciado' },
                  { value: 2, label: 'Pré-cadastro finalizado' },
                  { value: 3, label: 'Ajuste necessário (Umami. IA)' },
                  { value: 4, label: 'Ajuste necessário (Qualidade)' },
                  { value: 5, label: 'Finalizado Qualidade' },
                ]}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                defaultValue={data.quality_status}
                value={data.quality_status}
                onChange={(event, option: any) => {
                  if (option) {
                    setData({ ...data, quality_status: option });
                  }
                  onChange(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="quality_status"
                    label="Status Qualidade"
                    type="text"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.quality_status?.message}
                      </Typography>
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
          {props.typeForm && props.typeForm === 'create' && props.sequency ? (<>Salvar e continuar</>) : <>Salvar</>}
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default SkuForm;
